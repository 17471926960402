<template>
	<div class="details">
		<div class="details_title">{{data.title}}</div>
		<div class="details_subtitle">
			<span>{{data.createName}}</span>
			<span class="margin_left">{{data.issceTime}}</span>
		</div>
		<div class="details_content" v-html="content"></div>
		<div v-if="data.file&&data.file.length!=0" class="file_title">新闻附件：</div>
		<div v-if="data.file&&data.file.length!=0" class="details_file">
			<div v-for="(item,index) in data.file" :key="index">
				<a download="附件" :href="item">新闻附件下载</a>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getJoId
	} from "@/api/api";

	export default {
		name: "CompanyNews_details",
		inject: ["reload"],
		data() {
			return {
				data: [],
				content:''
			};
		},
		// 我是子组件，接收来自父组件的信息
		// props: ["id"],

		props: {
			id: String
		},
		mounted() {
			console.log(this.id);
			this.getJoId();
		},
		filters: {},
		methods: {
			getJoId() {
				getJoId(this.id)
					.then(res => {
						if (res.data.retData.file == null) {
							res.data.retData.file = [];
						} else {
							res.data.retData.file = res.data.retData.file.split(",");
						}

						this.data = res.data.retData;
						this.content=this.data.content;
						console.log('res',res.data.retData.content)
					})
					.catch(err => {
						console.log(err);
					});
			},
			fileNamefilter(val) {
				return val.split("-")[1];
			},
			toTop() {
				window.scrollTo(0, 0);
			},
		}
	};
</script>

<style lang="scss" scoped>
	$vm_w_base: 1920;
	$vm_h_base: 1080;

	@function vm_w($px) {
		@return ($px / 1920) * 100vw;
	}

	.details {
		max-width: 677px !important;
	}

	.details_title {
		font-size: vm_w(32);
		text-align: center;
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		color: #333333;
		margin-top: vm_w(93);
	}

	.details_subtitle {
		font-size: vm_w(14);
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		text-align: center;
		color: #999999;
		margin-top: vm_w(32);
	}

	.margin_left {
		margin-left: vm_w(62);
	}

	.details_content {
		width: 100%;
		margin-top: vm_w(32);
		padding-bottom: vm_w(64);

		/deep/ img {
			width: 100%;
		}
		/deep/ p{
			font-size: 14px;
		}
	}

	.file_title {
		font-size: vm_w(14);
		margin-top: vm_w(24);
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		text-align: left;
		color: #666666;
	}

	.details_file {
		margin-top: vm_w(12);
		width: 100%;

		& a {
			color: $chooseColor;
			margin-top: vm_w(20);
		}
	}

	.recommend_title {
		width: 100%;
		font-size: vm_w(32);
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		color: $chooseColor;

		& div {
			height: vm_w(32);
			width: vm_w(8);
			background: $chooseColor;
			border-radius: 4px;
			display: inline-block;
			line-height: vm_w(32);
			vertical-align: bottom;
			margin-right: vm_w(8);
			font-weight: 600;
		}
	}

	.recommend_items {
		width: 100%;
		display: table;
		margin-top: vm_w(32);

		& .item_img {
			width: vm_w(376);
			height: vm_w(268);

			& img {
				width: 100%;
			}
		}

		& .recommend_item {
			width: auto;
			cursor: pointer;
			background-color: #ffffff;

			& .item_text {
				width: vm_w(376);
				font-size: vm_w(18);
				line-height: vm_w(32);
				font-family: Source Han Sans CN, Source Han Sans CN-Medium;
				font-weight: 600;
				color: #333333;
			}
		}

		& .recommend_item:hover {
			box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
			-webkit-box-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
			-moz-box-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
			-ms-box-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
			-o-box-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
		}
	}

	::v-deep .ql-video {
		width: 100%;
	}
	::v-deep .ql-align-center{
		text-align: center;
	}
</style>